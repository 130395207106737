<template>
  <div class="category-selection">
    <h3>{{ $t("categoryHeading") }}</h3>
    <div class="categories">
      <div
        class="category-container"
        v-for="category in categories"
        :key="category.id"
        :style="{ animationDelay: `${index * 0.1}s` }"
      >
        <button
          class="category-button"
          :class="{ 'category-button-selected': category.expanded }"
          @click="selectCategory(category)"
        >
          {{ sportName(category.id) }}
        </button>
      </div>
    </div>
    <button class="category-button done-button" @click="done">
      {{ $t("categoryDone") }}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["sportsAreas", "uniqueId", "showGraph", "preferredLanguage"]),
    ...mapGetters(["getSportName"]),
  },
  data() {
    return {
      categories: [
        {
          id: 1,
          logs: [],
          expanded: false,
        },
        {
          id: 2,
          logs: [],
          expanded: false,
        },
        {
          id: 3,
          logs: [],
          expanded: false,
        },
        {
          id: 4,
          logs: [],
          expanded: false,
        },
        {
          id: 5,
          logs: [],
          expanded: false,
        },
        {
          id: 6,
          logs: [],
          expanded: false,
        },
        {
          id: 7,
          logs: [],
          expanded: false,
        },
        {
          id: 8,
          logs: [],
          expanded: false,
        },
        {
          id: 9,
          logs: [],
          expanded: false,
        },
        {
          id: 10,
          logs: [],
          expanded: false,
        },
        {
          id: 11,
          logs: [],
          expanded: false,
        },
        {
          id: 12,
          logs: [],
          expanded: false,
        },
        {
          id: 13,
          logs: [],
          expanded: false,
        },
        {
          id: 14,
          logs: [],
          expanded: false,
        },
        {
          id: 15,
          logs: [],
          expanded: false,
        },
        {
          id: 16,
          logs: [],
          expanded: false,
        },
        {
          id: 17,
          logs: [],
          expanded: false,
        },
        {
          id: 18,
          logs: [],
          expanded: false,
        },
        {
          id: 19,
          logs: [],
          expanded: false,
        },
        {
          id: 20,
          logs: [],
          expanded: false,
        },
        {
          id: 21,
          logs: [],
          expanded: false,
        },
        {
          id: 22,
          logs: [],
          expanded: false,
        },
        {
          id: 23,
          logs: [],
          expanded: false,
        },
        {
          id: 24,
          logs: [],
          expanded: false,
        },
        {
          id: 25,
          logs: [],
          expanded: false,
        },
        {
          id: 26,
          logs: [],
          expanded: false,
        },
        {
          id: 27,
          logs: [],
          expanded: false,
        },
        {
          id: 28,
          logs: [],
          expanded: false,
        },
        // Add more categories as needed
      ],
      oldSportsAreas: [],
    };
  },
  mounted() {
    // Check if sportsAreas is not empty
    if (this.$store.state.sportsAreas.length) {
      this.oldSportsAreas = this.$store.state.sportsAreas;
      // Iterate over sportsAreas
      this.$store.state.sportsAreas.forEach((selectedCategory) => {
        // Find all matching categories in this.categories
        const matchingCategories = this.categories.filter(
          (c) => c.id === selectedCategory.id
        );

        // If matching categories are found, set their expanded property to true
        matchingCategories.forEach((category) => {
          category.expanded = true;
        });
      });
    }
  },
  methods: {
    selectCategory(category) {
      category.expanded = !category.expanded;
      this.$store.commit("setSelectedCategories", {
        sportsAreas: this.categories.filter((c) => c.expanded),
      });
    },
    sportName(categoryId) {
      const sportKey = this.$store.state.sportNames[categoryId];
      return this.$t(sportKey);
    },
    async done() {
      if (process.env.VUE_APP_NODE_ENV !== "development") {
        const newSportsAreas = this.sportsAreas;
        const oldSportsAreas = this.oldSportsAreas;

        // GET existing persona
        const dbPersona = await fetch(`/api/GetPersona/${this.uniqueId}`);
        const existingPersona = await dbPersona.json();

        if (existingPersona.length > 0) {
          // Update unsubscribed sportsAreas
          for (let oldSportArea of oldSportsAreas) {
            if (
              !newSportsAreas.find(
                (sportArea) => sportArea.id === oldSportArea.id
              )
            ) {
              await fetch(`/api/PutPersona/`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  uniqueId: this.uniqueId,
                  preferredLanguage: this.preferredLanguage,
                  sportsAreas: this.sportsAreas,
                }),
              });
              console.log("SportsAreas", this.sportsAreas);
              console.log("Unsubscribed from", oldSportArea.id);
            }
          }

          let doUpdate = false;

          // PUT new sportsAreas
          for (let newSportArea of newSportsAreas) {
            if (
              !oldSportsAreas.find(
                (sportArea) => sportArea.id === newSportArea.id
              ) &&
              this.sportsAreas.find(
                (sportArea) => sportArea.id === newSportArea.id
              )
            ) {
              doUpdate = true;
            }
          }

          if (doUpdate) {
            console.log("Updating sportsAreas");
            console.log("newSportsAreas", this.sportsAreas);
            await fetch(`/api/PutPersona/`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                uniqueId: this.uniqueId,
                preferredLanguage: this.preferredLanguage,
                sportsAreas: this.sportsAreas,
              }),
            });
          }
        } else {
          // POST new persona
          const dbResponse = await fetch(`/api/PostPersona/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uniqueId: this.uniqueId,
              preferredLanguage: this.preferredLanguage,
              sportsAreas: this.sportsAreas,
            }),
          });
          // eslint-disable-next-line no-unused-vars
          const sportsAreas = await dbResponse.json();
        }
        this.$store.commit("setShowGraph", {
          showGraph: true,
        });
      }
      this.$store.commit("setShowGraph", {
        showGraph: true,
      });
    },
  },
};
</script>

<style scoped>
.categories {
  position: relative;
  width: 50%;
  height: auto;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.category-container {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInScaleUp 0.5s forwards;
}

@keyframes fadeInScaleUp {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.category-button {
  background-color: #9a9191;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
  width: 120px;
  height: 60px;
}

.category-button:hover {
  background-color: #0b731d;
}

.category-button.category-button-selected {
  background-color: #578d68;
}

.done-button {
  margin-top: 20px;
  background-color: #578d68;
}

@media screen and (max-width: 599px) {
  .category-container {
    width: 80px;
    height: 60px;
    margin: 2px;
  }

  .category-button {
    padding: 5px 10px;
    font-size: 12px;
    width: 80px;
    height: 40px;
  }

  .done-button {
    padding: 5px 10px;
    font-size: 12px;
    width: 80px;
    height: 40px;
  }
}
</style>
