import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";

const messages = {
  en: {
    loading: "Generating response...",
    characterLimit: "You have reached the maximum character limit.",
    filterLogs: "Filter progress logs",
    archery: "Archery",
    badminton: "Badminton",
    baseball: "Baseball",
    basketball: "Basketball",
    bouldering: "Bouldering",
    boxing: "Boxing",
    cycling: "Cycling",
    dancing: "Dancing",
    fitness: "Fitness",
    football: "Football",
    golf: "Golf",
    hiking: "Hiking",
    hockey: "Hockey",
    padel: "Padel",
    running: "Running",
    sailing: "Sailing",
    skateboard: "Skateboard",
    skiing: "Skiing",
    snowboard: "Snowboard",
    soccer: "Soccer",
    surfing: "Surfing",
    swimming: "Swimming",
    tennis: "Tennis",
    volleyball: "Volleyball",
    yoga: "Yoga",
    climbing: "Climbing",
    crossfit: "Crossfit",
    other: "Other",
    myHub: "My Hub",
    sportsInfo:
      "Use SportsBuddy to track your training and tailor your experience.",
    deselectSports:
      "Deselecting sports areas will result in deleting your saved progress logs",
    reselectSports: "Reselect Sports Areas",
    close: "Close",
    stats: "Your Stats",
    progressLogs: "Progress Logs",
    age: "Age",
    gender: "Gender:",
    level: "Level:",
    newEntry: "New Entry",
    saveEntry: "Save Entry",
    male: "Male",
    female: "Female",
    beginner: "Beginner",
    intermediate: "Intermediate",
    advanced: "Advanced",
    professional: "Professional",
    placeholderSportsCard: "Note down your progress and your sessions here...",
    signIn: "Sign In",
    signOut: "Sign Out",
    welcomeMsg: "Hello",
    description:
      "Sportsbuddy is a comprehensive sports assistant that tracks your progress in a personal log and adapts to your needs with tailored sports and nutrition advice.",
    additionaldescription:
      "This blend of features ensures that as you grow and improve in your sports endeavors, Sportsbuddy evolves with you, offering guidance that becomes more precise and relevant to your journey.",
    categoryHeading: "Select categories to subscribe to",
    categoryDone: "Done",
    aboutRouter: "About",
    aboutHeading: "About SportsBuddy",
    aboutdescription1:
      "SportsBuddy is dedicated to providing valuable information and advice about various sports and nutrition to help you achieve your sports goals.",
    aboutdescription2:
      "If you have any questions, suggestions, or collaboration inquiries, feel free to reach out to us at:",
    messageIntro: "Type your message here...",
    chatHistoryCollapse: "Chat History",
    newChat: "Save Chat",
    chatFilter: "Search chat history...",
    generateTitlePrompt:
      "Generate a title from the current conversation. The title can only be 30 characters long.",
    hoverMessage:
      "Press me to add logs about your progress and goals.<br>That will help me learn from you!",
    objectiveMessage: "About time to add a goal for the next 6 months?",
    objectiveYes: "Yes",
    objectiveNo: "No",
    messageYou: "Ready to work on the next big thing in sports. You!",
    addGoal: "Add Goal",
    goal: "Goal",
    writeGoal: "Write down your goal here...",
    keyResults: "Actions to reach your goal",
    addResult: "Add Result",
    addObjective: "Submit Goal",
    timeline: "Timeline",
    writeKeyResult: "Write down a key result here...",
    allCheckResults:
      "All key results are checked. Do you want to make a new goal?",
    sixMonthsPassed: "6 months have passed. Please set a new goal.",
    closeEntry: "Close Entry",
    // ... other translations ...
  },
  da: {
    loading: "Genererer svar...",
    characterLimit: "Du har nået den maksimale tegnegrænse.",
    filterLogs: "Filtrer fremgangslog",
    archery: "Bueskydning",
    badminton: "Badminton",
    baseball: "Baseball",
    basketball: "Basketball",
    bouldering: "Bouldering",
    boxing: "Boksning",
    cycling: "Cykling",
    dancing: "Dans",
    fitness: "Fitness",
    football: "Rugby",
    golf: "Golf",
    hiking: "Hiking",
    hockey: "Hockey",
    padel: "Padel",
    running: "Løb",
    sailing: "Sejling",
    skateboard: "Skateboard",
    skiing: "Ski",
    snowboard: "Snowboard",
    soccer: "Fodbold",
    surfing: "Surfing",
    swimming: "Svømning",
    tennis: "Tennis",
    volleyball: "Volleyball",
    yoga: "Yoga",
    climbing: "Klatring",
    crossfit: "Crossfit",
    other: "Andet",
    myHub: "Min Hub",
    sportsInfo:
      "Brug SportsBuddy til at spore din træning og tilpasse din oplevelse.",
    deselectSports:
      "Fravælgelse af sportsområder vil resultere i sletning af dine gemte fremgangslog",
    reselectSports: "Vælg Sportsområder Igen",
    close: "Luk",
    stats: "Dine Stats",
    progressLogs: "Fremgangslog",
    age: "Alder",
    gender: "Køn:",
    level: "Niveau:",
    newEntry: "Ny Log",
    saveEntry: "Gem Log",
    male: "Mand",
    female: "Kvinde",
    beginner: "Begynder",
    intermediate: "Øvet",
    advanced: "Avanceret",
    professional: "Professionel",
    placeholderSportsCard: "Noter din fremgang og dine sessioner her...",
    signIn: "Log ind",
    signOut: "Log ud",
    welcomeMsg: "Hej",
    description:
      "Sportsbuddy er en omfattende sportsassistent, der sporer dine fremskridt i en personlig log og tilpasser sig dine behov med skræddersyet sports- og ernæringsrådgivning.",
    additionaldescription:
      "Denne kombination af funktioner sikrer, at når du udvikler dig og forbedrer dig inden for sport, udvikler Sportsbuddy sig med dig og tilbyder vejledning, der bliver mere præcis og relevant for din rejse.",
    categoryHeading: "Vælg kategorier at abonnere på",
    categoryDone: "Færdig",
    aboutRouter: "Om os",
    aboutHeading: "Om SportsBuddy",
    aboutdescription1:
      "SportsBuddy er dedikeret til at levere værdifuld information og råd om forskellige sportsgrene og ernæring for at hjælpe dig med at nå dine sportsmål.",
    aboutdescription2:
      "Hvis du har spørgsmål, forslag eller samarbejdsforespørgsler, er du velkommen til at kontakte os på:",
    messageIntro: "Skriv din besked her...",
    chatHistoryCollapse: "Chat Historik",
    newChat: "Gem Chat",
    chatFilter: "Søg chat historik...",
    generateTitlePrompt:
      "Generer en titel fra den nuværende samtale. Titlen kan kun være 30 tegn lang.",
    hoverMessage:
      "Tryk på mig for at tilføje logs om dine fremskridt og mål.<br>Det vil hjælpe mig med at lære af dig!",
    objectiveMessage: "På tide at sætte et mål for de næste 6 måneder?",
    objectiveYes: "Ja",
    objectiveNo: "Nej",
    messageYou: "Klar til at arbejde på det næste store inden for sport. Dig!",
    addGoal: "Tilføj Mål",
    goal: "Mål",
    writeGoal: "Skriv dit mål her...",
    keyResults: "Tiltag for at nå målet",
    addResult: "Tilføj Resultat",
    addObjective: "Bekræft Mål",
    timeline: "Tidslinje",
    writeKeyResult: "Skriv et målbart resultat her...",
    allCheckResults:
      "Alle målbare resultater er gennemført. Vil du nulstille målet?",
    sixMonthsPassed: "6 måneder er gået. Venligst sæt et nyt mål.",
    closeEntry: "Luk Log",
    // ... other translations ...
  },
  no: {
    loading: "Genererer svar...",
    characterLimit: "Du har nådd maksimal tegngrense.",
    filterLogs: "Filtrer fremdriftslogg",
    archery: "Bueskyting",
    badminton: "Badminton",
    baseball: "Baseball",
    basketball: "Basketball",
    bouldering: "Bouldering",
    boxing: "Boksing",
    cycling: "Sykling",
    dancing: "Dansing",
    fitness: "Fitness",
    football: "Rugby",
    golf: "Golf",
    hiking: "Hiking",
    hockey: "Hockey",
    padel: "Padel",
    running: "Løping",
    sailing: "Seiling",
    skateboard: "Skateboard",
    skiing: "Ski",
    snowboard: "Snowboard",
    soccer: "Fotball",
    surfing: "Surfing",
    swimming: "Svømming",
    tennis: "Tennis",
    volleyball: "Volleyball",
    yoga: "Yoga",
    climbing: "Klatring",
    crossfit: "Crossfit",
    other: "Annet",
    myHub: "Min Hub",
    sportsInfo:
      "Bruk SportsBuddy til å spore treningen din og tilpasse opplevelsen din.",
    deselectSports:
      "Fravalg av sportsområder vil resultere i sletting av lagrede fremdriftslogger",
    reselectSports: "Velg Sportsområder Igjen",
    close: "Lukk",
    stats: "Dine Stats",
    progressLogs: "Fremdriftslogger",
    age: "Alder",
    gender: "Kjønn:",
    level: "Nivå:",
    newEntry: "Ny Logg",
    saveEntry: "Lagre Logg",
    male: "Mann",
    female: "Kvinne",
    beginner: "Nybegynner",
    intermediate: "Øvet",
    advanced: "Avansert",
    professional: "Profesjonell",
    placeholderSportsCard: "Noter fremdriften og øktene dine her..",
    signIn: "Logg inn",
    signOut: "Logg ut",
    welcomeMsg: "Hei",
    description:
      "Sportsbuddy er en omfattende sportsassistent som sporer fremgangen din i en personlig logg og tilpasser seg dine behov med skreddersydd sports- og ernæringsrådgivning.",
    additionaldescription:
      "Denne kombinasjonen av funksjoner sikrer at når du vokser og forbedrer deg i dine sportslige bestrebelser, utvikler Sportsbuddy seg med deg, og gir veiledning som blir mer presis og relevant for din reise.",
    categoryHeading: "Velg kategorier å abonnere på",
    categoryDone: "Ferdig",
    aboutRouter: "Om oss",
    aboutHeading: "Om SportsBuddy",
    aboutdescription1:
      "SportsBuddy er dedikert til å gi verdifull informasjon og råd om ulike idretter og ernæring for å hjelpe deg med å nå sportsmålene dine.",
    aboutdescription2:
      "Hvis du har spørsmål, forslag eller samarbeidsforespørsler, er du velkommen til å kontakte oss på:",
    messageIntro: "Skriv meldingen din her...",
    chatHistoryCollapse: "Chat Historikk",
    newChat: "Lagre Chat",
    chatFilter: "Søk chat historikk...",
    generateTitlePrompt:
      "Generer en tittel fra den nåværende samtalen. Tittelen kan bare være 30 tegn lang.",
    hoverMessage:
      "Trykk på meg for å legge til logger om fremgangen og målene dine.<br>Det vil hjelpe meg med å lære av deg!",
    objectiveMessage: "På tide å sette deg et mål for de neste 6 månedene?",
    objectiveYes: "Ja",
    objectiveNo: "Nei",
    messageYou: "Klar til å jobbe med det neste store innen sport. Deg!",
    addGoal: "Legg til Mål",
    goal: "Mål",
    writeGoal: "Skriv målet ditt...",
    keyResults: "Tiltak for å nå målet",
    addResult: "Legg til Resultat",
    addObjective: "Bekreft Mål",
    timeline: "Tidslinje",
    writeKeyResult: "Skriv et nøkkelresultat her...",
    allCheckResults:
      "Alle nøkkelresultater er sjekket. Vil du nullstille målet?",
    sixMonthsPassed: "6 måneder har gått. Vennligst sett et nytt mål.",
    closeEntry: "Lukk Logg",
    // ... other translations ...
  },
};

const i18n = createI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

createApp(App).use(i18n).use(store).use(router).mount("#app");
