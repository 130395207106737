<template>
  <div class="sign-in-container">
    <br />
    <br />
    <div>
      <button class="sign-in-button" @click="openSignInRedirect()">
        {{ $t("signIn") }}
      </button>
      <br />
      <br />
      <p>{{ $t("description") }}</p>
      <p>
        {{ $t("additionaldescription") }}
      </p>
      <div>
        <img
          :class="{ 'animate-image': isButtonClicked }"
          src="../assets/sportsBud.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as msal from "msal";
import { mapGetters } from "vuex";
import CryptoJS from "crypto-js";

export default {
  computed: { ...mapGetters(["myMSALObj"]) },
  props: {
    authenticated: Boolean,
  },
  data() {
    return {
      myMSALObjSession: null,
      isButtonClicked: false,
    };
  },
  created() {
    this.myMSALObjSession = new msal.UserAgentApplication(
      this.myMSALObj.controller.config
    );
  },
  mounted() {
    if (this.myMSALObjSession) {
      this.myMSALObjSession.handleRedirectCallback(async (error, response) => {
        if (error) {
          console.error(error);
          return;
        }

        this.isButtonClicked = true;
        // Handle the response
        if (this.myMSALObjSession.getAccount()) {
          //comment out the below autenticated line when doing local development, remember to uncomment it when deploying
          //this.$emit("authenticated", true); // Emit the 'authenticated' event with true
          this.$store.commit("setUserInfo", {
            userName: response["idToken"]["name"],
            uniqueId: response["uniqueId"],
          });

          // Assuming the secret key is stored in an environment variable
          const secretKey = process.env.VUE_APP_ENCRYPTION_KEY;

          // Parallelize API calls
          const [dbPersona, dbResponse] = await Promise.all([
            fetch(`/api/GetPersona/${response["uniqueId"]}`),
            fetch(`/api/GetChatHistory/${response["uniqueId"]}`),
          ]);

          let sportsAreas = await dbPersona.json();
          let chatHistory = await dbResponse.json();
          //console.log("chatResponse", chatHistory);
          //console.log("chatResponse", sportsAreas);

          if (sportsAreas.length > 0) {
            this.$store.commit("setShowGraph", {
              showGraph: true,
            });

            this.$store.commit("setPreferredLanguage", {
              preferredLanguage: sportsAreas[0].preferredLanguage,
            });

            // Decrypt each logEntry in sportsAreas
            sportsAreas = sportsAreas[0].sportsAreas.map((area) => {
              if (area.logs && area.logs.length > 0) {
                area = {
                  ...area,
                  logs: area.logs.map((log) => {
                    return {
                      ...log,
                      logEntry: this.decrypt(log.logEntry, secretKey),
                    };
                  }),
                };
              }
              if (area.okr && area.okr.length > 0) {
                area = {
                  ...area,
                  okr: area.okr.map((okr) => {
                    return {
                      ...okr,
                      objective: this.decrypt(okr.objective, secretKey),
                      keyResults: okr.keyResults.map((keyResult) => {
                        return this.decrypt(keyResult, secretKey);
                      }),
                    };
                  }),
                };
              }
              return area;
            });
            this.$store.commit("setSelectedCategories", {
              sportsAreas: sportsAreas,
            });
          }

          if (chatHistory.length > 0) {
            // Decrypt each chat history entry
            chatHistory = chatHistory[0].chatHistory.map((entry) => {
              return {
                ...entry,
                newEntry: {
                  ...entry.newEntry,
                  title: this.decrypt(entry.newEntry.title, secretKey),
                  history: entry.newEntry.history.map((historyEntry) => {
                    return {
                      ...historyEntry,
                      content: this.decrypt(historyEntry.content, secretKey),
                    };
                  }),
                },
              };
            });
            this.$store.commit("getChatHistory", {
              chatHistory: chatHistory,
            });
            this.$store.commit("setIsExistingUser", {
              isExistingUser: true,
            });
          } else {
            this.$store.commit("getChatHistory", {
              chatHistory: [],
            });
          }

          this.$emit("authenticated", true); // Emit the 'authenticated' event with true
          //console.log("chatHistory", this.$store.state.chatHistory);
          this.$store.commit("setMyMSALObject", {
            myMSALObj: this.myMSALObjSession,
          });
        } else {
          console.log("User not logged in");
          this.$emit("authenticated", false); // Emit the 'authenticated' event with false
        }
      });
    }
  },
  methods: {
    openSignInRedirect() {
      const loginRequest = {
        scopes: ["openid", "profile", process.env.VUE_APP_SCOPE],
      };
      this.myMSALObjSession.loginRedirect(loginRequest);
    },
    decrypt(encryptedData, secretKey) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
    },
  },
};
</script>

<style scoped>
.sign-in-container {
  text-align: center;
  margin: 20px;
}

.sign-in-button {
  background: linear-gradient(to right, #1a472a, #15a82e);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.sign-in-button:hover {
  background: linear-gradient(to right, #15a82e, #1a472a);
}

img {
  max-height: 50vh;
  max-width: 100%;
  margin-top: 20px;
}

p {
  width: 90%;
  margin: 10px auto;
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.animate-image {
  animation: scale-up 2s ease-in-out infinite;
}

/* Styles for screens that are less than 600px wide */
@media screen and (max-width: 599px) {
  p {
    font-size: 14px;
  }
}
</style>
